import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {callWithCatch, pp} from "./utils.js";
import MyWS from "./MyWS.js";
import {Card, Popover, Space, Spin} from "antd";
import {Bot} from "lucide-react";
import HtmlDiv from "./HtmlDiv.js";
import User from "./User.js";
import {NiceDrawer} from "./NiceDrawer.js";
import NiceModal from "@ebay/nice-modal-react";

const AiStream = observer(({q, doc_id,}) => {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(false);

    let run = async () => {
        setLoading(true);
        setResult(false);
        callWithCatch({
            url: `/searches/chat`,
            method: 'post',
            params: {relevancy: true, q, doc_id},
            onErr: () => {
                setLoading(false)
            },
            onSuccess: data => {
                let sub;
                if (data.text) {
                    setLoading(false);
                    setResult(data)
                }

                if (data.sub_to)
                    sub = MyWS(data.sub_to, r => {
                        setResult(r)
                        if (r.done) {
                            setLoading(false);
                            sub.unsubscribe();
                        }
                    })
            }
        })
    }

    useEffect(() => {
        run()
    }, [q])


    let title = <Space><Bot/><Spin/></Space>;
    if (result?.done)
        title = null;

    if (result?.err) {
        pp(result?.err)
        title = <Alert type="error" message="חלה שגיאה ביצירת התשובה" />
    }

    return <div className="w-full sm:flex-grow">
        {result &&
            <div style={{fontSize: "14px", whiteSpace: "pre-line"}} className="">
                {title}
                <HtmlDiv html={result.text}/>
                <br/>
                {result.done &&
                    <div style={{fontSize: "10px", color: "#9CA3AF"}}>סיכום הAI בבטא ויכול לעשות טעויות. אנא ודאו את
                        תשובותיו עם הפסיקה.</div>}
            </div>}
    </div>
})

let AnswerDrawer = NiceModal.create(observer(({ q, doc_id, title }) => {
    return <NiceDrawer title={title}>
        <AiStream q={q} doc_id={doc_id}/>
    </NiceDrawer>
}));

export default observer(({q, doc_id, title}) => {
    if (!q || !User.isAdmin())
        return null;

    let openPopup = e => {
        e.preventDefault();
        // setShow(true);
        NiceModal.show(AnswerDrawer, {q, doc_id, title});
    }

    return <a href="#" onClick={openPopup}><span className="hit-result-link">למה זה רלוונטי?</span></a>
});
